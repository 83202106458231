import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  doc,
  getDoc,
} from "firebase/firestore"
import { ORGANISATION_CONFIG_COLLECTION } from "../common/constants/collections"
import { db } from "../firebase"
import {
  DELIVERY_STATUS_ASSIGNED,
  DELIVERY_STATUS_COMPLETED,
  DELIVERY_STATUS_FAILED,
  DELIVERY_STATUS_IN_WAREHOUSE,
  DELIVERY_STATUS_MIXED,
  DELIVERY_STATUS_OPEN,
  DELIVERY_STATUS_OUT_FOR_DELIVERY,
  DELIVERY_STATUS_SELF_COLLECT,
  DELIVERY_STATUS_TRANSPORT_REQUEST,
} from "../common/constants/deliveryStatuses"
import { CARRIER_ROLE } from "../users/roles"

export type OptimisationOrigin = {
  lat?: number
  lng?: number
}

type OrganisationConfig = {
  organisationId: string
  organisationQueryId: string
  role: string
  usesPayments: boolean
  usesFetchUpdates: boolean
  usesCsvUpload: boolean
  usesChangeShipmentStatus: boolean
  defaultLocale: string | undefined
  deliveryStatuses: string[]
  usesPickups: boolean
  shipmentListHeaders: string[]
  barcodeRegex: string
  defaultCurrency: string
  shouldSendReceiverEmail: boolean
  shouldSendReceiverSms: boolean
  usesAccessPoints: boolean
  usesAssignedShipments: boolean
  usesWarehouseScan: boolean
  optimisationOrigin: OptimisationOrigin | undefined
  allowedForwarderIds: string[]
  allowedCarrierIds: string[]
}

export default OrganisationConfig

export const defaultOrganisationConfig: OrganisationConfig = {
  organisationId: "",
  organisationQueryId: "organisationId",
  role: CARRIER_ROLE,
  usesPayments: false,
  usesFetchUpdates: false,
  usesCsvUpload: false,
  usesChangeShipmentStatus: false,
  defaultLocale: "en",
  deliveryStatuses: [
    DELIVERY_STATUS_OUT_FOR_DELIVERY,
    DELIVERY_STATUS_COMPLETED,
    DELIVERY_STATUS_FAILED,
    DELIVERY_STATUS_IN_WAREHOUSE,
    DELIVERY_STATUS_MIXED,
    DELIVERY_STATUS_ASSIGNED,
    DELIVERY_STATUS_OPEN,
    DELIVERY_STATUS_TRANSPORT_REQUEST,
    DELIVERY_STATUS_SELF_COLLECT,
  ],
  usesPickups: false,
  shipmentListHeaders: [
    "receiverName",
    "dropoffAddress",
    "shipmentId",
    "deliveryStatus",
    "paymentStatus",
    "paymentAmount",
    "vehicleRegistration",
    "updatedDate",
  ],
  barcodeRegex: "",
  defaultCurrency: "",
  shouldSendReceiverEmail: false,
  shouldSendReceiverSms: false,
  usesAccessPoints: false,
  usesAssignedShipments: false,
  usesWarehouseScan: false,
  optimisationOrigin: undefined,
  allowedForwarderIds: [],
  allowedCarrierIds: [],
}

export const organisationConfigConverter: FirestoreDataConverter<OrganisationConfig> =
  {
    toFirestore(config: OrganisationConfig): DocumentData {
      return config
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): OrganisationConfig {
      const data = snapshot.data(options)
      const config: OrganisationConfig = {
        organisationId: snapshot.id,
        organisationQueryId:
          data.organisationQueryId ??
          defaultOrganisationConfig.organisationQueryId,
        role: data.role ?? defaultOrganisationConfig.role,
        usesPayments:
          data.usesPayments ?? defaultOrganisationConfig.usesPayments,
        usesFetchUpdates:
          data.usesFetchUpdates ?? defaultOrganisationConfig.usesFetchUpdates,
        usesCsvUpload:
          data.usesCsvUpload ?? defaultOrganisationConfig.usesCsvUpload,
        usesChangeShipmentStatus:
          data.usesChangeShipmentStatus ??
          defaultOrganisationConfig.usesChangeShipmentStatus,
        defaultLocale:
          data.defaultLocale ?? defaultOrganisationConfig.defaultLocale,
        deliveryStatuses:
          data.deliveryStatuses ?? defaultOrganisationConfig.deliveryStatuses,
        usesPickups: data.usesPickups ?? defaultOrganisationConfig.usesPickups,
        shipmentListHeaders:
          data.shipmentListHeaders ??
          defaultOrganisationConfig.shipmentListHeaders,
        barcodeRegex:
          data.barcodeRegex ?? defaultOrganisationConfig.barcodeRegex,
        defaultCurrency:
          data.defaultCurrency ?? defaultOrganisationConfig.defaultCurrency,
        shouldSendReceiverEmail:
          data.shouldSendReceiverEmail ??
          defaultOrganisationConfig.shouldSendReceiverEmail,
        shouldSendReceiverSms:
          data.shouldSendReceiverSms ??
          defaultOrganisationConfig.shouldSendReceiverSms,
        usesAccessPoints:
          data.usesAccessPoints ?? defaultOrganisationConfig.usesAccessPoints,
        usesAssignedShipments:
          data.usesAssignedShipments ??
          defaultOrganisationConfig.usesAssignedShipments,
        usesWarehouseScan:
          data.usesWarehouseScan ?? defaultOrganisationConfig.usesWarehouseScan,
        optimisationOrigin:
          data.optimisationOrigin ??
          defaultOrganisationConfig.optimisationOrigin,
        allowedForwarderIds: data.allowedForwarderIds ?? [snapshot.id],
        allowedCarrierIds: data.allowedCarrierIds ?? [snapshot.id],
      }
      return config
    },
  }

export const getConfig = async (organisationId: string) => {
  const configSnapshot = await getDoc(
    doc(db, ORGANISATION_CONFIG_COLLECTION, organisationId).withConverter(
      organisationConfigConverter
    )
  )

  return configSnapshot.data()
}

export type SettingItemType = "toggle" | "select" | "input" | "list-input"

export type SelectOption = { value: string; text: string }

export interface SettingItem {
  key: string
  type: SettingItemType
  options?: SelectOption[]
}

export const settings: SettingItem[] = [
  {
    key: "barcodeRegex",
    type: "input",
  },
  {
    key: "defaultCurrency",
    type: "select",
    options: [
      {
        value: "ISK",
        text: "ISK",
      },
      {
        value: "USD",
        text: "USD",
      },
    ],
  },
  {
    key: "defaultLocale",
    type: "select",
    options: [
      {
        value: "is",
        text: "Icelandic",
      },
      {
        value: "en",
        text: "English",
      },
    ],
  },
  {
    key: "deliveryStatuses",
    type: "list-input",
  },
  {
    key: "shipmentListHeaders",
    type: "list-input",
  },
  {
    key: "role",
    type: "select",
    options: [
      {
        value: "carrier",
        text: "Carrier",
      },
      {
        value: "forwarder",
        text: "Forwarder",
      },
    ],
  },
  {
    key: "shouldSendReceiverEmail",
    type: "toggle",
  },
  {
    key: "shouldSendReceiverSms",
    type: "toggle",
  },
  {
    key: "usesAccessPoints",
    type: "toggle",
  },
  {
    key: "usesAssignedShipments",
    type: "toggle",
  },
  {
    key: "usesChangeShipmentStatus",
    type: "toggle",
  },
  {
    key: "usesCsvUpload",
    type: "toggle",
  },
  {
    key: "usesFetchUpdates",
    type: "toggle",
  },
  {
    key: "usesPayments",
    type: "toggle",
  },
  {
    key: "usesPickups",
    type: "toggle",
  },
  {
    key: "usesWarehouseScan",
    type: "toggle",
  },
  {
    key: "optimisationOriginLat",
    type: "input",
  },
  {
    key: "optimisationOriginLon",
    type: "input",
  },
]
