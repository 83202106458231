export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
export const ENV = process.env.REACT_APP_ENV
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const TYPESENSE_SHIPMENTS_SEARCH_INDEX =
  process.env.REACT_APP_TYPESENSE_SHIPMENTS_SEARCH_INDEX
export const TYPESENSE_SAVED_LOCATIONS_SEARCH_INDEX =
  process.env.REACT_APP_TYPESENSE_SAVED_LOCATIONS_SEARCH_INDEX
export const TYPESENSE_PAYMENTS_SEARCH_INDEX =
  process.env.REACT_APP_TYPESENSE_PAYMENTS_SEARCH_INDEX
export const TYPESENSE_API_KEY = process.env.REACT_APP_TYPESENSE_API_KEY
export const TYPESENSE_HOST = process.env.REACT_APP_TYPESENSE_HOST
export const GOOGLE_LMFS_PROJECT_ID =
  process.env.REACT_APP_GOOGLE_LMFS_PROJECT_ID
export const GOOGLE_LMFS_API_KEY = process.env.REACT_APP_GOOGLE_LMFS_API_KEY
export const RECEIVER_BASE_URL = process.env.REACT_APP_RECEIVER_BASE_URL
